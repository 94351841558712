import React, { useState, useEffect, useMemo } from 'react'
import style from './Index.module.less'
import { message, Row, Col, Select, Skeleton } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import { getListRequest } from './ajax'
import { awaitWrap } from '@/assets/js/tool'
import Item from './components/Item/Index'
import Dictionary from '@/assets/js/dictionary'

// 初始化案件列表
async function initCaseList (setLoading, setDataSource, status) {
  setLoading(true)
  const [e, d] = await awaitWrap(getListRequest(status))
  setLoading(false)
  if (e !== null) {
    await undefined
  } else if (d === null) {
    await message.error('案件列表未返回')
  } else {
    setDataSource(d)
  }
}

const changeFn = _.debounce(initCaseList, 500)

function Main ({ history }) {
  const [loading, setLoading] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [isFixed, setIsFixed] = useState(window.document.documentElement.offsetWidth >= 1280)
  const [status, setStatus] = useState('全部')
  const [types, setTypes] = useState([])
  const options = useMemo(() => {
    return [{ label: '全部', value: '全部' }, ...types]
  }, [types])
  useEffect(() => {
    const d = new Dictionary()
    d.init('课程分类')
      .then(() => {
        setTypes(d.getList('课程分类').map(li => ({ label: li.title, value: li.value })))
      })
  }, [])
  useEffect(() => {
    changeFn(setLoading, setDataSource, status)
  }, [status])
  useEffect(() => {
    function initFixed () {
      setIsFixed(window.document.documentElement.offsetWidth >= 1280)
    }
    window.addEventListener('resize', initFixed)
    return () => {
      window.removeEventListener('resize', initFixed)
    }
  }, [])
  return (
    <div className={style.container}>
      <div className={style.content}>
        <Row gutter={24} className={style.form}>
          <Col span={16}>
            <div className={style['page-title']}>线上课程</div>
          </Col>
          <Col span={8}>
            <Select className={style['status-select']} size='large' value={status} placeholder='请选择' onChange={e => setStatus(e)} options={options} />
          </Col>
        </Row>
        <div className={style.list}>
          <Skeleton active loading={loading} />
          {dataSource.map((li, i) => {
            return (
              <Item data={li} key={i} history={history} />
            )
          })}
        </div>
      </div>
      <div className={classNames(style['right-block'], { [style.fixed]: isFixed })}>
        <div className={style['operate-btn']} />
      </div>
    </div>
  )
}

export default Main
